import * as CryptoJS from 'crypto-js';
import * as environment from '../config/environment';

const encryptWithAes = (key: string, iv: string, data: string) : string => {

    var aesKey = CryptoJS.enc.Utf8.parse(key);
    var aesIv = CryptoJS.enc.Utf8.parse(iv);
    var encryptedValue = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(data),
        aesKey,
        {
            keySize: 32,
            iv: aesIv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }
    );

    return encryptedValue.toString();
}

const generateAesKey = () => {
    return randomKey(32);
}

const generateAesIV = () => {
    return randomKey(16);
}

const randomKey = (length: number) => {
    var result = '';
    var characters = '@abcdefghijklmnopqrstuvwxyz123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export {encryptWithAes, generateAesKey, generateAesIV}