import React from 'react'
import Footer from './footer/Footer'
export default function Layout ({ children }:any) {
  return (
    <div className='my-2'>
        <main>{children}</main>
        <Footer/>
    </div>
  )
}
