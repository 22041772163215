import React, { useState } from "react";
import Cards, { Focused } from "react-credit-cards";
import { useLocation, useNavigate } from "react-router-dom";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "./MakePaymentValidation";
import "./MakePayment.css";
import "react-credit-cards/es/styles-compiled.css";
import PaymentAPI from "../../apis/PaymentAPI";
import IPaymentRequest from "../../models/IPaymentRequest";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SessionStorageSevice from "../../services/StorageService";
import { MakePaymentSubmitForm } from "../../types/form-inputs/MakePaymentSubmitForm";
import { moneyFormat } from "../../helpers/MoneyFormat";
import IVerifySecure3dData from "../../models/IVerifySecure3dData";
import { toast } from "react-toastify";
import Secure3dModal from "./secure-3d-modal/Secure3dModal";

export default function MakePayment() {
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [secure3dModalOpen, setSecure3dModalOpen] = useState(false);
  const [verifySecure3dDataState, setVerifySecure3dDataState] =
    useState<IVerifySecure3dData>();
  const [cardState, setCardState] = useState({
    cvc: "",
    expiry: "",
    name: "",
    number: "",
    focused: "",
  });
  const { state }: any = useLocation();
  const userInfo = SessionStorageSevice.getUserInfo();
  const navigate = useNavigate();
  const handleInputFocus = (e: any) => {
    setCardState({
      ...cardState,
      ["focused"]: e.target.name,
    });
  };

  const secure3dModalToggle = () => setSecure3dModalOpen(!secure3dModalOpen);
  const validationSchema = yup.object().shape({
    number: yup.string().required("Kart numarası gereklidir"),
    name: yup.string().required("Kart sahibinin adı soyadı gereklidir"),
    expiry: yup.string().required(" "),
    cvc: yup.string().required(" "),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<MakePaymentSubmitForm>({
    resolver: yupResolver(validationSchema),
  });

  const handleInputChange = (e: any) => {
    if (e.target.name === "number") {
      e.target.value = formatCreditCardNumber(e.target.value);
    } else if (e.target.name === "expiry") {
      e.target.value = formatExpirationDate(e.target.value);
    } else if (e.target.name === "cvc") {
      e.target.value = formatCVC(e.target.value);
    } else {
      e.target.value = e.target.value.replace(/[^a-zA-ZğüşöçıİĞÜŞÖÇ\s]+/, "");
    }
    setCardState({
      ...cardState,
      [e.target.name]: e.target.value,
    });
  };

  const backToDebtQuery = () => {
    navigate("/selected-debts", {
      state: {
        selectedDebts: state.selectedDebts,
        paymentSummary: state.paymentSummary,
        debtType: state.debtType,
      },
    });
  };

  const start3DSecureFlow = (paymentRequest: IPaymentRequest) => {
    PaymentAPI.verifySecure3d(paymentRequest)
      .then((response: any) => {
        setIsLoadingState(false);
        if (response.success) {
          setVerifySecure3dDataState(response.data);
          secure3dModalToggle();
        }
      })
      .catch((e: Error) => {
        setIsLoadingState(false);
      });
  };

  const onSubmit = (data: any, event: any) => {
    event.preventDefault();
    setIsLoadingState(true);
    let paymentRequest: IPaymentRequest = {
      creditCardNumber: cardState.number.replace(/ /g, ""),
      cardHolderName: cardState.name,
      cardCvv: cardState.cvc,
      cardExpiryMonth: Number(cardState.expiry.substring(0, 2)),
      cardExpiryYear: Number("20" + cardState.expiry.substring(3, 5)),
      debtType: state.debtType,
      amount: state.paymentSummary.paymentAmount,
      selectedDebtReferenceId: state.paymentSummary.referenceId,
      bankId: state.selectedBank.bankId,
    };
    //TODO api entegrasoyonu yapılacak
    if (state.selectedBank.isSecure3d) {
      start3DSecureFlow(paymentRequest);
    } else {
      setIsLoadingState(false);
      return toast.error("Yapmak istediğiniz işlem desteklenmemektedir");
    }
  };

  return (
    <div className="container p-3">
      <div className="row">
        <h6 className="mb-4">Güvenli Ödeme Sayfası</h6>
        <div className="col-md-12 col-lg-9">
          <div className="col-lg-8 col-sm-12 col-md-6">
            <div className="form-group col-sm-12 col-md-12 col-lg-8 mb-2">
              <label className="mb-2">Adı Soyadı</label>
              <input
                type="text"
                name="name"
                value={userInfo?.fullName}
                className="form-control"
                placeholder="Adı Soyadı"
                disabled={true}
              />
            </div>
            <div className="form-group col-sm-12 col-md-12 col-lg-8 mb-2">
              <label className="mb-2">Sicil No</label>
              <input
                type="text"
                name="name"
                value={userInfo?.registryNumber}
                className="form-control"
                placeholder="Sicil Kodu"
                disabled={true}
              />
            </div>
            <div className="form-group col-sm-12 col-md-12 col-lg-5 mb-2">
              <label className="mb-2">Ödenecek Tutar</label>
              <input
                type="text"
                name="name"
                value={moneyFormat(state?.paymentSummary?.paymentAmount)}
                className="form-control"
                placeholder="Tutar"
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div>
          <hr />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="p-3">
          <div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="mb-2">
                  <small>Kredi kartı bilgilerinizi giriniz</small>
                </div>
                <div className="form-group mb-4 input-group">
                  <span className="input-group-text">
                    <i className="fa fa-credit-card"></i>
                  </span>
                  <input
                    type="tel"
                    placeholder="Kart Numarası"
                    onInput={handleInputChange}
                    onFocus={handleInputFocus}
                    {...register("number")}
                    className={`form-control ${
                      errors.number ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.number?.message}
                  </div>
                </div>
                <div className="form-group mb-4 input-group">
                  <span className="input-group-text">
                    <i className="fa fa-user"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Kart Sahibinin Adı Soyadı"
                    onInput={handleInputChange}
                    onFocus={handleInputFocus}
                    {...register("name")}
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">{errors.name?.message}</div>
                </div>
                <div className="row form-group mb-3">
                  <div className="col-lg-6 mb-4">
                    <div className="form-group input-group">
                      <span className="input-group-text">
                        <i className="fa fa-lock"></i>
                      </span>
                      <input
                        type="tel"
                        placeholder="CVC"
                        pattern="\d{3,4}"
                        onInput={handleInputChange}
                        onFocus={handleInputFocus}
                        {...register("cvc")}
                        className={`form-control ${
                          errors.cvc ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.cvc?.message}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group input-group">
                      <span className="input-group-text">
                        <i className="fa fa-calendar"></i>
                      </span>
                      <input
                        type="tel"
                        {...register("expiry")}
                        className={`form-control ${
                          errors.expiry ? "is-invalid" : ""
                        }`}
                        name="expiry"
                        placeholder="AA/YY"
                        pattern="\d\d/\d\d"
                        onInput={handleInputChange}
                        onFocus={handleInputFocus}
                      />
                      <div className="invalid-feedback">
                        {errors.expiry?.message}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4 ms-1">
                  <button
                    type="button"
                    className="btn btn-secondary col-sm-5 me-5 mb-1 fw-bold"
                    onClick={backToDebtQuery}
                    disabled={isLoadingState}
                  >
                    Geri Dön
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success text-white col-sm-5 mb-1 fw-bold"
                    disabled={isLoadingState}
                  >
                    Ödeme Yap
                    {isLoadingState && (
                      <div
                        className="spinner-border text-light spinner-border-sm ms-2"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
              <div className="col-lg-8 d-none d-sm-none d-md-none d-lg-block">
                <div className="col-lg-5 mb-3">
                  <Cards
                    cvc={cardState.cvc}
                    expiry={cardState.expiry}
                    name={cardState.name}
                    number={cardState.number}
                    focused={cardState.focused as Focused}
                    acceptedCards={["visa", "mastercard", "amex"]}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <Secure3dModal
          setSecure3dModalOpen={setSecure3dModalOpen}
          secure3dModalToggle={secure3dModalToggle}
          secure3dModalOpen={secure3dModalOpen}
          cardState={cardState}
          state={state}
          verifySecure3dDataState={verifySecure3dDataState}
        />
      </div>
    </div>
  );
}
