const downloadFile = (res: any, name:string)=> {
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob([res.data]));
    let newFile = name;
    downloadLink.setAttribute('download', newFile);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export {downloadFile}