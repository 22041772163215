import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import StructuredDebts from './components/structured-debts/StructuredDebts';
import UnstructuredDebts from './components/unstructured-debts/UnstructuredDebts';
import SessionStorageSevice from '../../services/StorageService';
import './DebtQuery.css'
import { DebtTypeEnum } from '../../types/enums/DebtTypeEnum';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
function DebtQuery() {

  const userInfo =  SessionStorageSevice.getUserInfo();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!userInfo?.registryNumber) {
      navigate("/");
    }
  }, []);
  
  
  return (
      <div className="container-fluid p-3 ">
        <div className="row">
          <div className="col-lg-12">
            <span className='mb-3 fw-bold font-size-lg'>Borç Sorgulama</span>
            <div>
              <p>İlgili sicil bilgilerine sahip vatandaşa ait borçları görüntülemek için aşağıdaki alanları kullanarak arama yapabilirsiniz.</p>
            </div>
            <div className='mb-4'>
              <div className='col-lg-4 col-md-6 col-xl-6'>
                <i className="fa fa-user me-1 text-center fa-lg"></i> 
                <span className='mb-3 fw-bold'>Vatandaş Bilgileri</span>
                <hr className='col-sm-12 col-md-10 col-lg-8'/>
              </div>
              <div className='mb-2 col-sm-12 col-md-10 col-lg-8 col-xl-6 row'>
                <div className='col-md-2 my-auto'><label htmlFor="">Sicil No</label></div>
                <div className='col-md-6'><input className="form-control" type="text" disabled={true} value={userInfo?.registryNumber}/></div>
              </div>
              <div className='mb-2  col-sm-12 col-md-10 col-lg-8 col-xl-6 row'>
                <div className='col-md-2 my-auto'><label htmlFor="">Adı Soyadı</label></div>
                <div className='col-md-6'><input className="form-control" type="text" disabled={true} value= {userInfo?.fullName}/></div>
              </div>
              <div className='mb-2 col-sm-12 col-md-10 col-lg-8 col-xl-6 row'>
                <div className='col-md-2 my-auto'><label htmlFor="">Baba Adı</label></div>
                <div className='col-md-6'><input className="form-control" type="text" disabled={true} value={userInfo?.fatherName}/></div>
              </div>
              <div className='mb-2 col-sm-12 col-md-10 col-lg-8 col-xl-6 row'>
                <div className='col-md-2 my-auto'><label htmlFor="">Anne Adı</label></div>
                <div className='col-md-6'><input className="form-control" type="text" disabled={true} value={userInfo?.motherName}/></div>
              </div>
              <div className='mb-2 col-sm-12 col-md-10 col-lg-8 col-xl-6 row'>
                <div className='col-md-2 my-auto'><label htmlFor="">Ünvanı</label></div>
                <div className='col-md-6'><input className="form-control" type="text" disabled={true} value={userInfo?.title}/></div>
              </div>
            </div>
          </div>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Tabs defaultActiveKey={DebtTypeEnum.UnstructuredDebt} mountOnEnter={true} id="uncontrolled-tab-example" className="mb-3 col-sm-12 col-md-12 col-lg-12 fw-bold" justify>
              <Tab eventKey={DebtTypeEnum.UnstructuredDebt} title="Yapılandırmasız Borçlar">
                <UnstructuredDebts />
              </Tab>
              <Tab eventKey={DebtTypeEnum.StructuredDebt} title="Yapılandırma Borçları">
                <StructuredDebts/>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  };

  export default DebtQuery;