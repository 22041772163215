import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PaymentResultSendSmsModalSubmitForm } from '../../../types/form-inputs/PaymentResultSendSmsModalSubmitForm';
import { formatPhoneNumber } from '../../payment-security/PaymentSecurityValidation';
import PaymentAPI from '../../../apis/PaymentAPI';
import ISendPaymentDetailBySmsRequest from '../../../models/ISendPaymentDetailBySmsRequest';
import { toast } from 'react-toastify';

export default function SendSmsModal({modalOpen, toggle, referenceId, setSendSmsModalOpen}:any) {
    const nodeRef = React.useRef(null);
    const [isLoadingState, setIsLoadingState] = useState(false);
    const validationSchema = yup.object().shape({
        phoneNumber: yup.string().required("Cep telefonu gereklidir").min(12, 'Cep telefonu en az 10 haneli olmalıdır'),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
      } = useForm<PaymentResultSendSmsModalSubmitForm>({
        resolver: yupResolver(validationSchema)
    });
    const onSubmit = (data: PaymentResultSendSmsModalSubmitForm, event:any) => {
        event.preventDefault();
        setIsLoadingState(true);
        let sendPaymentDetailBySmsRequest : ISendPaymentDetailBySmsRequest = {
            phoneNumber:data.phoneNumber.replace(/\D/g, ""),
            referenceId:referenceId
        };
        PaymentAPI.sendPaymentDetailBySms(sendPaymentDetailBySmsRequest).then((response: any) => {
            setIsLoadingState(false);
            setSendSmsModalOpen(false);
            reset();
            if(response.success) {
                toast.success(response.data);
            }
        })
          .catch((e: Error) => {
            setIsLoadingState(false);
            reset();
            setSendSmsModalOpen(false);
        });
    };

    useEffect(() => {
    }, [toggle]);
     
    return (
        <>
            <Modal isOpen={modalOpen} toggle={toggle} className="modal-dialog-centered modal-lg" ref={nodeRef} backdrop="static">
                <form data-testid="form" onSubmit={handleSubmit(onSubmit)} className="p-3">
                    <ModalHeader>Sms Olarak Gönder</ModalHeader>
                    <ModalBody>
                        <div className="form-group row mb-4">
                            <div className='col-sm-3'>
                                <label className='mt-1'>Cep Telefonu</label>
                            </div>
                            <div className='col-sm-6'>
                                <input
                                type="text"
                                data-testid="phone"
                                placeholder='999 999 9999'
                                onInput={ (e:any) =>{e.target.value = formatPhoneNumber(e.target.value)}}
                                {...register('phoneNumber')}
                                className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">{errors.phoneNumber?.message}</div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button"className='btn btn-secondary me-2 mb-1 fw-bold' onClick={()=>{toggle();reset();}} disabled={isLoadingState}>Vazgeç</button>
                        <button className='btn btn-success mb-1 fw-bold' type="submit" data-testid="submit" disabled={isLoadingState}>
                            Gönder
                            {isLoadingState &&(
                                <div className="spinner-border text-light spinner-border-sm ms-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            )}
                        </button>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    )
}
