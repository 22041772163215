export const API_URL = process.env.REACT_APP_API_URL;
export const GOOGLE_RECAPTCHA_KEY = "" + process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;
export const PUBLIC_KEY = "" + process.env.REACT_APP_PUBLIC_KEY;
export const TITLE = process.env.REACT_APP_TITLE;
export const FOOTER_PREFIX = process.env.REACT_APP_FOOTER_PREFIX;
export const FOOTER_SAMPAS_TEXT = process.env.REACT_APP_FOOTER_SAMPAS_TEXT;
export const FOOTER_SAMPAS_LINK = process.env.REACT_APP_FOOTER_SAMPAS_LINK;
export const EBELEDIYE_LINK = process.env.REACT_APP_EBELEDIYE_LINK;
export const EBELEDIYE_TEXT = process.env.REACT_APP_EBELEDIYE_TEXT;
export const EBELEDIYE_LOGO = process.env.REACT_APP_EBELEDIYE_LOGO;
