import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import PaymentAPI from "../../../apis/PaymentAPI";
import IPaymentAuthorizeRequest from "../../../models/IPaymentAuthorizeRequest";
import "./Secure3dModal.css";

export default function Secure3dModal({
  secure3dModalOpen,
  secure3dModalToggle,
  cardState,
  state,
  verifySecure3dDataState,
  setSecure3dModalOpen,
}: any) {
  const [isLoadingState, setIsLoadingState] = useState(true);
  const [isSubmittingState, setIsSubmittingState] = useState(false);
  const navigate = useNavigate();
  var listeners: Array<any> = [];

  const iframeRef = React.useCallback(
    (ref: HTMLIFrameElement) => {
      const win = ref?.contentWindow;
      if (win != null) {
        const doc = win.document;
        win.name = "3DS Challenge";
        doc.open();
        doc.write(verifySecure3dDataState?.htmlContent);
        doc.close();
      }
    },
    [verifySecure3dDataState]
  );

  const paymentAuthorize = (
    resultBase64: string,
    isSuccess: boolean,
    isCompleted: boolean
  ) => {
    if (resultBase64 && isSuccess) {
      if (isCompleted) {
        //3dpay icin eklendi, su anda aktif değil
        setIsSubmittingState(false);
        setSecure3dModalOpen(false);
        navigate("/payment-result", {
          state: {
            selectedDebts: state.selectedDebts,
            paymentSummary: state.paymentSummary,
            debtType: state.debtType,
          },
        });
        return;
      } else {
        let paymentAuthorizeRequest: IPaymentAuthorizeRequest = {
          secure3dBase64Response: resultBase64,
          creditCardNumber: cardState.number.replace(/ /g, ""),
          cardHolderName: cardState.name,
          cardCvv: cardState.cvc,
          cardExpiryMonth: Number(cardState.expiry.substring(0, 2)),
          cardExpiryYear: Number("20" + cardState.expiry.substring(3, 5)),
          debtType: state.debtType,
          amount: state.paymentSummary.paymentAmount,
          selectedDebtReferenceId: state.paymentSummary.referenceId,
          bankId: state.selectedBank.bankId,
        };
        setIsSubmittingState(true);
        PaymentAPI.authorize(paymentAuthorizeRequest)
          .then((response: any) => {
            setIsSubmittingState(false);
            setSecure3dModalOpen(false);
            if (response.success) {
              navigate("/payment-result", {
                state: {
                  selectedDebts: state.selectedDebts,
                  paymentSummary: state.paymentSummary,
                  debtType: state.debtType,
                },
              });
            }
          })
          .catch((e: Error) => {
            setIsSubmittingState(false);
            setSecure3dModalOpen(false);
          });
      }
    } else {
      setIsSubmittingState(false);
      setSecure3dModalOpen(false);
      toast.error(
        "3D Güvenli Ödeme adımı tamamlanamadı, lütfen tekrar deneyiniz"
      );
    }
  };

  function messageListener(event: any) {
    if (event?.data?.type === "Secure3dResponse") {
      if (listeners.length > 0) {
        listeners.pop();
      }
      let boolIsSuccess = JSON.parse(event.data.isSuccess?.toLocaleLowerCase());
      let boolIsCompleted = JSON.parse(
        event.data.isCompleted?.toLocaleLowerCase()
      );
      paymentAuthorize(event.data.result, boolIsSuccess, boolIsCompleted);
    }
  }

  useEffect(() => {
    window.addEventListener("message", messageListener);
    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, [cardState]);

  return (
    <div>
      <Modal
        isOpen={secure3dModalOpen}
        toggle={secure3dModalToggle}
        className="modal-dialog-centered modal-lg"
        backdrop="static"
      >
        <ModalHeader toggle={secure3dModalToggle}>3D Güvenli Ödeme</ModalHeader>

        <ModalBody className="text-center">
          {(isLoadingState || isSubmittingState) && (
            <div className="d-flex justify-content-center spinner">
              <div
                className="row spinner-border text-success spinner-border ms-2"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          {secure3dModalOpen && (
            <iframe
              src=""
              title="myFrame"
              frameBorder={0}
              ref={iframeRef}
              height={400}
              width={435}
              onLoad={() => setIsLoadingState(false)}
            ></iframe>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}
