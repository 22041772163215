import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import PaymentAPI from '../../apis/PaymentAPI';
import { moneyFormat } from '../../helpers/MoneyFormat';
import IPaymentDetail from '../../models/IPaymentDetail';
import IPaymentDetailDebtItem from '../../models/IPaymentDetailDebtItem';
import SessionStorageSevice from '../../services/StorageService';
import { DebtTypeEnum } from '../../types/enums/DebtTypeEnum';
import SendEmailModal from './send-email-modal/SendEmailModal';
import SendSmsModal from './send-sms-modal/SendSmsModal';
import './PaymentResult.css'
import { today } from '../../helpers/TodayDate';
import { downloadFile } from '../../helpers/DownloadFile';
import * as environment from '../../config/environment';

export default function PaymentResult() {
    const [sendSmsModalOpen, setSendSmsModalOpen] = useState(false);
    const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
    const [isLoadingState, setIsLoadingState] = useState(false);
    const [isReceiptPdfSubmittingState, setIsReceiptPdfSubmittingState] = useState(false);
    const [paymentDetailState, setPaymentDetailState] = useState<IPaymentDetail>();
    const {state}:any = useLocation();
    const userInfo =  SessionStorageSevice.getUserInfo();
    const sendSmsModalToggle = () => setSendSmsModalOpen(!sendSmsModalOpen);
    const sendEmailModalToggle = () => setSendEmailModalOpen(!sendEmailModalOpen);
    const navigate = useNavigate();
    
    useEffect(() => {
        if(!state || !state.selectedDebts || !state.paymentSummary || !state.debtType || !userInfo){
            navigate("/");
        }
        paymentDetail();
        window.onpopstate = onBackButtonEvent;
    }, [])
    
    const onBackButtonEvent = () => {
        return navigate("/debt-query");
    }

    const paymentDetail = () => {
        setIsLoadingState(true);
        PaymentAPI.detail(state.paymentSummary.referenceId).then((response: any) => {
            setIsLoadingState(false);
            if(response.success){
              setPaymentDetailState(response.data);
            }
        })
        .catch((e: Error) => {
            setIsLoadingState(false); 
        });
    }
    
    const exportPaymentDetailReceiptPdf = () => { 
        setIsReceiptPdfSubmittingState(true);
        PaymentAPI.exportPaymentDetailReceiptPdf(state.debtType, state.paymentSummary.referenceId).then((response: any) => {
            setIsReceiptPdfSubmittingState(false);
            downloadFile(response, (paymentDetailState?.registryNumber + "-Tahsilat Dekontu - " + today + ".pdf"));
        })
        .catch((e: Error) => {
            setIsReceiptPdfSubmittingState(false); 
        });
    }

    return (
        <div className="container-fluid p-3">
            <div className='col-sm-12'>
                <div className="row mb-3">
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-2 img-div-width"> 
                        <img alt='belediye-logo' src="belediye-logo.jpg" height="200px"/> 
                    </div>
                    <div className="col-sm-12 my-auto col-md-12 col-lg-4 col-xl-3" dangerouslySetInnerHTML={ {__html: paymentDetailState?.headerText || ''}}></div>
                </div>
                <div className='mb-4'>
                    <div className='mb-2 col-sm-12 col-md-10 col-lg-8 col-xl-6 row'>
                        <div className='col-md-2 my-auto fw-bold'><label htmlFor="">Sicil Numarası</label></div>
                        <div className='col-md-6'><input className="form-control" type="text" disabled={true} value = {paymentDetailState?.registryNumber || ''}/></div>
                    </div>
                    <div className='mb-2  col-sm-12 col-md-10 col-lg-8 col-xl-6 row'>
                        <div className='col-md-2 my-auto fw-bold'><label htmlFor="">Adı Soyadı</label></div>
                        <div className='col-md-6'><input className="form-control" type="text" disabled={true} value = {(paymentDetailState?.firstName + ' ' || '')  + (paymentDetailState?.lastName || '')} /></div>
                    </div> 
                    <div className='mb-2 col-sm-12 col-md-10 col-lg-8 col-xl-6 row'>
                        <div className='col-md-2 my-auto fw-bold'><label htmlFor="">Tarih</label></div>
                        <div className='col-md-6'><input className="form-control" type="text" disabled={true} value = {String(paymentDetailState?.paymentDate || '')}/></div>
                    </div>
                    <div className='mb-2 col-sm-12 col-md-10 col-lg-8 col-xl-6 row'>
                        <div className='col-md-2 my-auto fw-bold'><label htmlFor="">Makbuz No</label></div>
                        <div className='col-md-6'><input className="form-control" type="text" disabled={true} value = {paymentDetailState?.receiptNumber || ''}/></div>
                    </div>
                    <div className='mb-2 col-sm-12 col-md-10 col-lg-8 col-xl-6 row'>
                        <div className='col-md-2 my-auto fw-bold'><label htmlFor="">Seri Numarası</label></div>
                        <div className='col-md-6'><input className="form-control" type="text" disabled={true} value = {paymentDetailState?.serialNumber || ''}/></div>
                    </div>
                </div>
                <div className='mb-4'>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                {(state?.debtType === DebtTypeEnum.UnstructuredDebt) &&(
                                    <th className='text-center'>Yılı</th>
                                )}
                                {(state?.debtType === DebtTypeEnum.UnstructuredDebt) &&(
                                    <th>Sıra</th>
                                )}
                                {(state?.debtType === DebtTypeEnum.UnstructuredDebt) &&(
                                    <th>İş/Abone</th>
                                )}
                                
                                {(state?.debtType === DebtTypeEnum.UnstructuredDebt) &&(
                                    <th className='text-center'>Son Ödeme Tarihi</th>
                                )}
                                {(state?.debtType === DebtTypeEnum.UnstructuredDebt) &&(
                                    <th>Gelir Kodu</th>
                                )}
                                <th>Gelir Adı</th>
                                <th className='text-end'>Vergi Tutarı</th>
                                <th className='text-end'>Gecikme Faizi</th>
                                <th className='text-end'>Gecikme Zammı</th>
                                <th className='text-end'>Toplam Borç</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paymentDetailState?.debts.map((debt:IPaymentDetailDebtItem, index:any) => (
                                <tr key={index}>
                                    {(state?.debtType === DebtTypeEnum.UnstructuredDebt) &&(
                                        <td className='text-center'>{debt?.yearPeriod}</td>
                                    )}
                                    {(state?.debtType === DebtTypeEnum.UnstructuredDebt) &&(
                                        <td>{debt?.orderNo}</td>
                                    )}
                                    {(state?.debtType === DebtTypeEnum.UnstructuredDebt) &&(
                                        <td>{debt?.subscriberNumber}</td>
                                    )}
                                    {(state?.debtType === DebtTypeEnum.UnstructuredDebt) &&(
                                        <td className='text-center'>{ debt.lastPaymentDate}</td>
                                    )}
                                    {(state?.debtType === DebtTypeEnum.UnstructuredDebt) &&(
                                        <td>{debt?.incomeCode}</td>
                                    )}                               
                                    <td>{debt?.incomeName}</td>
                                    <td className='text-end'>{moneyFormat(debt?.taxDebtAmount)}</td>
                                    <td className='text-end'>{moneyFormat(debt?.interestDebtAmount)}</td>
                                    <td className='text-end'>{moneyFormat(debt?.lateFeeAmount)}</td>
                                    <td className='text-end'>{moneyFormat(debt?.totalDebtAmount)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className="row justify-content-end mb-4">
                        <label className="col-xl-2 col-lg-3 col-sm-6 my-auto fw-bold total-debt-label">Toplam Gecikme Zammı</label>
                        <div className='col-lg-2 col-sm-6'>
                            <input type="text" className="form-control text-end" value={moneyFormat(state?.paymentSummary?.lateFeeAmount)} disabled={true}/>
                        </div>
                    </div>
                    <div className="row justify-content-end mb-4">
                        <label className="col-xl-2 col-lg-3 col-sm-6 my-auto fw-bold total-debt-label">Toplam Ödenen Borç</label>
                        <div className='col-lg-2 col-sm-6'>
                            <input type="text" className="form-control text-end" value={moneyFormat(state?.paymentSummary?.paymentAmount)} disabled={true}/>
                        </div>
                    </div>
                </div>
                <div className='mb-3'>
                    <span>Sayın <span className="text-uppercase">{paymentDetailState?.firstName} {paymentDetailState?.lastName}:</span> {moneyFormat(state?.paymentSummary?.paymentAmount)} Tutarındaki Borcunuz Tahsil EDİLMİŞTİR.</span>  
                </div>
                <div className='mb-3'>
                    <p>
                        NOT: Bu e-tahsilat dekontunun belirttiği bilgiler ile belediye kayıtları arasında farklılık oluşursa, belediye kayıtları esas alınır ve bu belge 
                        yazılı delil başlangıcı dahi teşkil etmeyecektir. Bu belgenin resmi geçerliliği yoktur. Resmi evrak olarak kullanmak isterseniz belediyeye başvurunuz. 
                    </p>  
                    <p>
                        1 TL'den az olan gecikme zammı kanunen 1 TL'ye yuvarlanmaktadır.
                    </p>
                </div>
                <div className='mb-4'>
                <span dangerouslySetInnerHTML={ {__html: paymentDetailState?.footerText || ''}}></span>  
                </div>
                <div>
                    <a href={environment.EBELEDIYE_LINK}>
                        <button type="button" className="btn btn-primary-color text-white me-3 fw-bold mb-1" >{environment.EBELEDIYE_TEXT}</button>
                    </a>
                    <button type="button" className="btn btn-secondary me-3 text-white mb-1 fw-bold" onClick={onBackButtonEvent} disabled={isReceiptPdfSubmittingState}>
                        Borçları Listele
                    </button>
                    <button type="button" className="btn btn-success text-white me-3 mb-1 fw-bold" disabled={isReceiptPdfSubmittingState} onClick={exportPaymentDetailReceiptPdf}>
                        Makbuzu Yazdır 
                        {isReceiptPdfSubmittingState &&(
                            <div className="spinner-border text-light spinner-border-sm ms-2" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        )}
                    </button>
                    <button type="button" className="btn btn-primary-color me-3 text-white mb-1 fw-bold" onClick={sendEmailModalToggle} disabled={isReceiptPdfSubmittingState}>
                        Mail Adresime Gönder  
                    </button>
                    <button type="button" className="btn btn-primary-color text-white me-3 mb-1 fw-bold" onClick={sendSmsModalToggle} disabled={isReceiptPdfSubmittingState}>
                        Sms Gönder  
                    </button>
                </div>
            </div>
            <SendSmsModal toggle = {sendSmsModalToggle} modalOpen = {sendSmsModalOpen} referenceId= {state?.paymentSummary?.referenceId} setSendSmsModalOpen ={setSendSmsModalOpen}/>
            <SendEmailModal toggle = {sendEmailModalToggle} modalOpen = {sendEmailModalOpen} referenceId= {state?.paymentSummary?.referenceId} debtType= {state?.debtType} setSendEmailModalOpen ={setSendEmailModalOpen}/>
        </div>
    )
}
