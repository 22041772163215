import * as environment from '../config/environment';
import { BaseAPI } from "./BaseAPI";

class BankAPI extends BaseAPI {
  
  constructor () {
    const baseUrl = environment.API_URL + "banks";
    super(baseUrl);
  }

  getActiveBanks(){
    return this.get();
  }

}

export default new BankAPI();