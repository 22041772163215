import React, { useRef, useState } from 'react'
import {Tab } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import { formatPhoneNumber } from "./PaymentSecurityValidation";
import Recaptcha from '../../components/Recaptcha'
import SendVerificationCodeModal from './components/send-verification-code-modal/SendVerificationCodeModal';
import './PaymentSecurity.css'
import * as yup from 'yup';
import AuthAPI from '../../apis/AuthAPI';
import IOtpRequest from '../../models/IOtpRequest';
import IVerifyOTPRequest from '../../models/IVerifyOTPRequest';
import { QueryTypeEnum } from '../../types/enums/QueryTypeEnum';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PaymentSubmitForm } from '../../types/form-inputs/PaymentSubmitForm';
import IOtp from '../../models/IOtp';
import * as environment from '../../config/environment';

export default function PaymentSecurity() {
  const [key, setKey] = useState(QueryTypeEnum.IdentityNumber);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [otpVerifyState, setOtpVerifyState] = useState<IVerifyOTPRequest>();
  const [handleRecaptchaChange, setHandleRecaptchaChange] = useState(false);

  const validationSchema = yup.object().shape({
    identityNumber: key === QueryTypeEnum.IdentityNumber ? yup.string().required("TC kimlik numarası gereklidir").min(11, 'Tc kimlik numarası 11 haneli olmalıdır') : yup.string(),
    registryNumber: (key === QueryTypeEnum.SubscriberNumber || key === QueryTypeEnum.RegistryNumber )? yup.string().required("Sicil numarası gereklidir").max(15, 'Sicil numarası en fazla 15 haneli olmalıdır') : yup.string(),
    subscriberNumber: key === QueryTypeEnum.SubscriberNumber? yup.string().required("Abone numarası gereklidir").max(8, 'Abone numarası en fazla 8 haneli olmalıdır') : yup.string(),
    taxNumber: key === QueryTypeEnum.TaxNumber? yup.string().required("Vergi numarası gereklidir").min(10, 'Vergi numarası en az 10 haneli olmalıdır') : yup.string(),
    phoneNumber: yup.string().required("Cep telefonu gereklidir").min(12, 'Cep telefonu en az 10 haneli olmalıdır'),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors}
  } = useForm<PaymentSubmitForm>({
    resolver: yupResolver(validationSchema),
    mode:"onChange"
  });
  const recaptchaTokenRef: any = useRef();
  const toggle = () => setModalOpen(!modalOpen);

  const getRecaptchaTokenRef = () => {
    return recaptchaTokenRef.current?.handleSubmit();
  }
  const onSubmit = (data: PaymentSubmitForm ,event:any) => {
    event.preventDefault();
    setIsLoadingState(true);
    const captchaToken = getRecaptchaTokenRef();

    let otpRequest : IOtpRequest = {
      captchaToken : captchaToken,
      queryType : key,
      phoneNumber : data.phoneNumber.replace(/\D/g, ""),
      identityNumber : data.identityNumber,
      subscriberNumber: Number(data.subscriberNumber),
      taxNumber:data.taxNumber,
      registryNumber:Number(data.registryNumber)
    };

    AuthAPI.sendOtp(otpRequest).then((response: any) => {
      recaptchaTokenRef.current?.reset();
      setIsLoadingState(false);
      if(response.success) {
        let verifyState:IOtp = response.data;
        if (otpRequest.queryType === QueryTypeEnum.SubscriberNumber && otpRequest.subscriberNumber !== null) {
          verifyState.subscriberNumber = otpRequest.subscriberNumber
        }
        setOtpVerifyState(verifyState);
        toggle();
      }
    })
    .catch((e: Error) => {
      recaptchaTokenRef.current?.reset();
      setIsLoadingState(false);
    });
  };

  return (
    <div className="container-fluid p2">
      <Tabs defaultActiveKey={QueryTypeEnum.IdentityNumber} className="mb-3 col-sm-12 bg-light fw-bold" justify activeKey={key} onSelect = {(k:any) => {setKey(Number(k));}}> 
        <Tab eventKey={QueryTypeEnum.IdentityNumber} title="T.C Kimlik Noya Göre Tahsilat" className='m-2'/>
        <Tab eventKey={QueryTypeEnum.RegistryNumber} title="Sicile Göre Tahsilat" />
        <Tab eventKey={QueryTypeEnum.SubscriberNumber} title="İş/Abone Noya Göre Tahsilat" />
        <Tab eventKey={QueryTypeEnum.TaxNumber} title="Vergi Kimlik Noya Göre Tahsilat"/>
      </Tabs>
      <div className="d-flex justify-content-center">
        <div className="flex-column col-xxl-4 col-xl-6 col-lg-8 col-md-10 col-12 mt-4">
          <div className="p-3 shadow bg-light">
            <form onSubmit={handleSubmit(onSubmit)} className="p-3">
              {key === QueryTypeEnum.IdentityNumber &&( 
                <div className="form-group row mb-4">
                  <div className='col-sm-6 my-auto'>
                    <label htmlFor='identityNumber'>TC Kimlik No</label>
                  </div>
                  <div className='col-sm-6'>
                    <input
                      type="number"
                      {...register('identityNumber')}
                      className={`form-control ${errors.identityNumber ? 'is-invalid' : ''}`}
                      onInput={ (e:any) =>{e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,11)}}
                      min={0}
                    />
                    <div className="invalid-feedback">{errors.identityNumber?.message}</div>
                  </div>
                </div>
              )}
              {(key === QueryTypeEnum.SubscriberNumber || key === QueryTypeEnum.RegistryNumber) &&( 
                <div className="form-group row mb-4">
                  <div className="col-sm-6 my-auto">
                    <label htmlFor='registryNumber'>Sicil No</label>
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="number"
                      {...register('registryNumber')}
                      className={`form-control ${errors.registryNumber ? 'is-invalid' : ''}`}
                      onInput={ (e:any) =>{e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,15)}}
                      min={0}
                    />
                    <div className="invalid-feedback">{errors.registryNumber?.message}</div>
                  </div>
                </div>
              )}
              {key === QueryTypeEnum.SubscriberNumber &&(
                <div className="row form-group mb-4">
                  <div className="col-sm-6 my-auto">
                    <label htmlFor='subscriberNumber'>Abone No</label>
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="number"
                      {...register('subscriberNumber')}
                      className={`form-control ${errors.subscriberNumber ? 'is-invalid' : ''}`}
                      onInput={ (e:any) =>{e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,8)}}
                      min={0}
                    />
                    <div className="invalid-feedback">{errors.subscriberNumber?.message}</div>
                  </div>
                </div>
              )}
              {key === QueryTypeEnum.TaxNumber &&(
                <div className="row form-group mb-4">
                  <div className="col-sm-6 my-auto">
                    <label htmlFor='taxNumber'>Vergi No</label>
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="number"
                      {...register('taxNumber')}
                      className={`form-control ${errors.taxNumber ? 'is-invalid' : ''}`}
                      onInput={(e:any)=>{ e.target.value = e.target.value.slice(0,10)}}
                      min={0}
                    />
                    <div className="invalid-feedback">{errors.taxNumber?.message}</div>
                  </div>
                </div>
              )}
              <div className="form-group mb-4 row">
                <div className="col-sm-6 my-auto">
                  <label htmlFor='phoneNumber'>Cep Telefonu</label>
                </div>
                <div className="col-sm-6">
                  <input
                    type="text"
                    placeholder='999 999 9999'
                    {...register('phoneNumber')}
                    className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                    onInput={ (e:any) =>{e.target.value = formatPhoneNumber(e.target.value)}}
                  />
                  <div className="invalid-feedback">{errors.phoneNumber?.message}</div>
                </div>
              </div>
              <div className="row mb-4">
                <div className='d-flex justify-content-sm-end g-recaptcha'> 
                    <Recaptcha ref={recaptchaTokenRef} changeHandleRecaptcha={(handleCaptcha:any) => setHandleRecaptchaChange(handleCaptcha)}/>     
                </div>
              </div>
              <div className="row form-group mb-4">
                <div className="col-12 col-sm-6">
                  <a href={environment.EBELEDIYE_LINK}>
                    <button type="button" className="btn btn-primary-color text-white me-1 mb-1 fw-bold" >{environment.EBELEDIYE_TEXT}</button>
                  </a>
                </div>
                <div className="text-sm-end col-12 col-sm-6">
                  <button type="submit" className="btn btn-success mb-1 me-1 fw-bold" disabled={!handleRecaptchaChange || isLoadingState}>
                    Sms Gönder 
                    {isLoadingState &&(
                      <div className="spinner-border text-light spinner-border-sm ms-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                  <button type="button" className="btn btn-secondary mb-1 fw-bold" onClick={() => reset()} disabled={isLoadingState} >Temizle</button>
                </div>
              </div>
            </form>
          </div>
          <div className="mt-5 pt-5 d-flex justify-content-center">
            <img src="belediye-logo.jpg" alt="belediye-logo" width={"auto"} />  
          </div>
        </div>

      </div>
      <SendVerificationCodeModal toggle={toggle} modalOpen = {modalOpen} otpVerifyState={otpVerifyState}/>
    </div>
  )
}

