import * as environment from '../config/environment';
import IPaymentRequest from "../models/IPaymentRequest";
import IPaymentAuthorizeRequest from '../models/IPaymentAuthorizeRequest';
import { BaseAPI } from "./BaseAPI";
import ISendPaymentDetailBySmsRequest from '../models/ISendPaymentDetailBySmsRequest';
import { DebtTypeEnum } from '../types/enums/DebtTypeEnum';
import ISendPaymentDetailByEmailRequest from '../models/ISendPaymentDetailByEmailRequest';

class PaymentAPI extends BaseAPI {
  
  constructor () {
    const baseUrl = environment.API_URL + "payments";
    super(baseUrl);
  }

  verifySecure3d(paymentRequest:IPaymentRequest){
    return this.post("secure3d/verify", paymentRequest, true);
  }

  authorize(paymentAuthorizeRequest:IPaymentAuthorizeRequest){
    return this.post("secure3d/authorize", paymentAuthorizeRequest, true);
  }

  detail(referenceId:number){
    return this.get("detail", {params:{referenceId:referenceId}});
  }

  sendPaymentDetailBySms(sendPaymentDetailBySmsRequest:ISendPaymentDetailBySmsRequest){
    return this.post("detail/send-sms", sendPaymentDetailBySmsRequest);
  }
  
  sendPaymentDetailByEmail(sendPaymentDetailByEmailRequest:ISendPaymentDetailByEmailRequest){
    return this.post("detail/send-email", sendPaymentDetailByEmailRequest);
  }

  exportPaymentDetailReceiptPdf(debtType:DebtTypeEnum, referenceId:number){
    return this.getFile("detail/export-pdf", {params:{debtType:debtType, referenceId:referenceId}});
  }

}

export default new PaymentAPI();