import React from 'react'
import { Table } from 'react-bootstrap'
import { dateFormat } from '../helpers/DateFormat'
import { moneyFormat } from '../helpers/MoneyFormat'
import IStructuredDebt from '../models/IStructuredDebt'
import IUnstructuredDebt from '../models/IUnstructuredDebt'
import { DebtTypeEnum } from '../types/enums/DebtTypeEnum'

export default function DebtsTable({selectedDebts, debtType}:any) {
  return (
    <div className='mb-4'>
        {(debtType === DebtTypeEnum.UnstructuredDebt &&
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>Grubu</th>
                    <th className='text-center'>Son Ödeme Tarihi</th>
                    <th>Türü</th>
                    <th>İş/Abone No</th>
                    <th>Ada/Parsel</th>
                    <th>Adres</th>
                    <th className='text-center'>Yılı</th>
                    <th className='text-center'>Dönemi</th>
                    <th>Sıra No</th>
                    <th className='text-end'>Borç Tutarı</th>
                    <th className='text-end'>Gecikme Faizi</th>
                    <th className='text-end'>Gecikme Zammı</th>
                    <th className='text-end'>Toplam Borç</th>
                    <th className='text-end'>Yasal İndirim</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedDebts?.map((debt:IUnstructuredDebt, index:any) => (
                    <tr key={index}>
                        <td>{debt.systemName}</td>
                        <td className='text-center'>{ dateFormat(debt.lastPaymentDate)}</td>
                        <td>{debt.incomeName}</td>
                        <td>{debt.subscriberNumber}</td>
                        <td>{debt.parcel}</td>
                        <td className='col-sm-2'>{debt.address}</td>
                        <td className='text-center'>{debt?.year}</td>
                        <td className='text-center'>{debt?.period}</td>
                        <td>{debt?.orderNo}</td>
                        <td className='text-end'>{moneyFormat(debt?.taxDebtAmount)}</td>
                        <td className='text-end'>{moneyFormat(debt?.interestDebtAmount)}</td>
                        <td className='text-end'>{moneyFormat(debt?.lateFeeAmount)}</td>
                        <td className='text-end'>{moneyFormat(debt?.totalDebtAmount)}</td>
                        <td className='text-end'>{moneyFormat(debt?.discountAmount)}</td>
                    </tr>
                    ))}
                </tbody>
            </Table>
        )}
        {(debtType === DebtTypeEnum.StructuredDebt &&
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>Evrak No</th>
                    <th>Yapılandırma Türü</th>
                    <th className='text-center'>Vadesi</th>
                    <th>Taksit No</th>
                    <th className='text-end'>Yapılandırma Tutarı</th>
                    <th className='text-end'>Gecikme Faizi</th>
                    <th className='text-end'>Gecikme Zammı</th>
                    <th className='text-end'>Toplam Taksit Tutarı</th>
                    </tr>
                </thead>
                <tbody>
                {selectedDebts.map((debt:IStructuredDebt, index:any) => (
                    <tr key={index}>
                        <td>{debt.documentNumber}</td>
                        <td>{debt.installmentType}</td>
                        <td className='text-center'>{ dateFormat(debt.lastPaymentDate)}</td>
                        <td>{debt.periodOrder}</td>
                        <td className='text-end'>{moneyFormat(debt.taxDebtAmount)}</td>
                        <td className='text-end'>{moneyFormat(debt.interestDebtAmount)}</td>
                        <td className='text-end'>{moneyFormat(debt.lateFeeAmount)}</td>
                        <td className='text-end'>{moneyFormat(debt.totalDebtAmount)}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        )}
    </div>
  )
}
