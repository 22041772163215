function clearNumber(value = "") {
  return value.replace(/\D/g, "");
}

export function formatPhoneNumber(value:any) {
  if (!value) {
    return value;
  }
  const clearValue = clearNumber(value);
  let nextValue = `${clearValue.slice(0, 3)} ${clearValue.slice(3,6)} ${clearValue.slice(6, 10)}`;

  return nextValue.trim();
}