import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import { moneyFormat } from "../../../../helpers/MoneyFormat";
import { findStructuredTableRowColor } from "../../../../helpers/FindStructuredTableRowColor";
import DebtAPI from '../../../../apis/DebtAPI';
import IStructuredDebt from '../../../../models/IStructuredDebt';
import { DebtTypeEnum } from '../../../../types/enums/DebtTypeEnum';
import { DiscountTypeEnum } from '../../../../types/enums/DiscountTypeEnum';
import ISelectedDebtsRequest from '../../../../models/ISelectedDebtRequest';
import ISelectedDebtSummary from '../../../../models/ISelectedDebtSummary';
import { downloadFile } from '../../../../helpers/DownloadFile';
import { today } from '../../../../helpers/TodayDate';
import SessionStorageSevice from '../../../../services/StorageService';
import ErrorMessageModal from '../../../../components/ErrorMessageModal';
import { dateFormat } from '../../../../helpers/DateFormat';
import * as environment from '../../../../config/environment';

export default function StructuredDebts() {
    const [debtsState, setDebtsState] = useState<Array<IStructuredDebt>>([]);
    const [structeredDebtsState, setStructeredDebtsState] = useState<Array<IStructuredDebt>>([]);
    const [selectedDebtState, setSelectedDebtState] = useState<IStructuredDebt | null>();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [isSubmittingState, setIsSubmittingState] = useState(false);
    const [isExportPdfSubmittingState, setIsExportPdfSubmittingState] = useState(false);
    const [systemOptionsState, setSystemOptionsState] = useState<Array<string>>([]);
    const [errorMessageModalOpen, setErrorMessageModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState<String>();
    const errorMessageModalToggle = () => setErrorMessageModalOpen(!errorMessageModalOpen);
    const userInfo =  SessionStorageSevice.getUserInfo();
    const navigate = useNavigate();
    const {handleSubmit,handleChange,values,resetForm} = useFormik({
        initialValues:{
            documentNumber:"",
            installmentTypeName:"",
            periodOrder:"",
        },
        onSubmit: (values) => {
        },
    });
    
    const reset =()=>{
        resetForm();
    };

    const validateCheckItem = (selectedDebt:IStructuredDebt) => {
        if (debtsState.filter(d => d.documentNumber === selectedDebt.documentNumber 
            && d.installmentType === selectedDebt.installmentType 
            && d.lastPaymentDate < selectedDebt.lastPaymentDate).length > 0) {
            setErrorMessage("Vadesi seçtiğiniz borçtan daha eski borcunuz bulunmaktadır öncelikle vadesi eski borçlarınızı ödemelisiniz");
            errorMessageModalToggle();    
            return false; 
        }  
        return true;
    }

    const handleOnChange = (selectedDebt:IStructuredDebt) => {

        if (selectedDebtState && selectedDebtState?.referenceId === selectedDebt.referenceId) {
            setSelectedDebtState(null);
            return;
        } 
        // Kullanıcının seçtiği borcun  aynı evrak numarası ve yapılandırma türüne göre   
        // vade tarihinden daha eski borç varsa check edemeyecek ekrana aşağıdaki şekilde uyarı penceresi açacak.
        if(validateCheckItem(selectedDebt)){
            setSelectedDebtState(selectedDebt);
        }
    };
    
    useEffect(() => {
        callStructuredDebtsQuery();
    },[]);

    useEffect(() => {
        filter();
    },[debtsState]);

    const filteredDebtData = (data:any) => {
        if(values.installmentTypeName && data.installmentTypeName !== values.installmentTypeName) return false;
        if(values.documentNumber && data.documentNumber !== values.documentNumber) return false;
        if(values.periodOrder && data.periodOrder !== values.periodOrder) return false;
        return true;
    }
    
    const filter = () =>{
        const debtData = debtsState.filter(filteredDebtData);
        setStructeredDebtsState(debtData);
        setSelectedDebtState(null);
    }

    const callStructuredDebtsQuery = () => {
        setIsloading(true);
        DebtAPI.queryStructeredDebts().then((response: any) => {
            setIsloading(false);
            setShowErrorMessage(false);
            if(response.success){
                setDebtsState(response.data);
                setStructeredDebtsState(response.data);
                getAllStructuredDebtType(response.data);
            }
        })
        .catch((e) => {
            setIsloading(false);
            setShowErrorMessage(true);
        });
    }
    
    const onSubmit = (event:any) => {
        event.preventDefault();
        if(selectedDebtState === undefined || selectedDebtState === null){
            setErrorMessage("Ödeme yapmak için bir borç seçmelisiniz");
            errorMessageModalToggle();  
            return;
        }
        if(selectedDebtState !== null){
            validateCheckItem(selectedDebtState);
        }
        let newArray:IStructuredDebt[] = [];
        newArray.push(selectedDebtState as IStructuredDebt);
        const debtIds = newArray.map((x:any)=>x.referenceId);
        let selectedDebtsRequest:ISelectedDebtsRequest={
            debtType:DebtTypeEnum.StructuredDebt,
            discountSelection:DiscountTypeEnum.HasDiscount, 
            debtIds:debtIds
        }
        setIsSubmittingState(true);
        DebtAPI.setSelectedDebts(selectedDebtsRequest).then((response: any) => {
            setIsSubmittingState(false);
            let paymentSummary:ISelectedDebtSummary = response.data;
            if(response.success) {
                navigate("/selected-debts",{state:{selectedDebts:newArray, debtType:DebtTypeEnum.StructuredDebt, paymentSummary:paymentSummary}});
            } 
           
        })
          .catch((e: Error) => {
            setIsSubmittingState(false);
        });
    }

    const getAllStructuredDebtType = (debts:Array<IStructuredDebt>) => {
        let options:string[] = debts.map((e)=>e.installmentTypeName);
        options = options.filter((n:any,i:any) => n && options.indexOf(n) === i);
        options.sort((a, b) => a.localeCompare(b));
        setSystemOptionsState(options);
    }

    const exportDebtPdf = () =>{
        setIsExportPdfSubmittingState(true)
        DebtAPI.exportDebtPdf(DebtTypeEnum.StructuredDebt).then((response: any) => {
            setIsExportPdfSubmittingState(false);
            downloadFile(response, (userInfo?.registryNumber + "-YapılandırmalıBorçlar - " + today + ".pdf"));
        })
        .catch((e: Error) => {
            setIsExportPdfSubmittingState(false);
        });
    }
    const isCheckedDebt = (debt:IStructuredDebt, index:number) => {
        return selectedDebtState?.referenceId === structeredDebtsState[index].referenceId;
    }

    const back = () => {
        navigate("/");
    }

    return (
        <>
            {(!showErrorMessage && !isLoading && debtsState.length !== 0) &&(
                <div>
                    <div className='mt-4'>
                        <div className='col-lg-10 col-md-6 col-xl-6'>
                            <i className="fa fa-filter me-1 text-center fa-lg"></i> 
                            <span className='mb-3 fw-bold'>Borç Sorgulama Filtreleri</span>
                            <hr className='col-lg-8'/>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className='row col-lg-7 col-md-6 mb-4'>
                                <div className='col-lg'>
                                    <label className="mb-1">Evrak No</label>
                                    <div>
                                        <input type="text" className="form-control" id = 'documentNumber' value = {values.documentNumber} onChange = {handleChange}/>
                                    </div>
                                </div>
                                <div className='col-lg'>
                                    <label className="mb-1">Yapılandırma Türü</label>
                                     <div>
                                        <select  className="form-select" aria-label="Default select example" name="installmentTypeName" value = {values.installmentTypeName} onChange = {handleChange}>
                                        <option value="">Tümü</option>
                                        {systemOptionsState.map((option:string, index:any) => (
                                            <option value={option} key={index}>{option}</option>
                                        ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg'>
                                    <label className="mb-1">Taksit No</label>
                                    <div>
                                        <input type="number" className="form-control" id = 'periodOrder' value = {values.periodOrder} onChange = {handleChange}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4 col-lg-7">
                                <div className="text-end">
                                    <button type="button" className="btn btn-secondary me-3 mb-1 fw-bold" onClick={reset} disabled={isLoading}>Temizle</button>
                                    <button type="submit" className="btn btn-success mb-1 fw-bold" onClick={callStructuredDebtsQuery} disabled={isLoading}>Borçları Listele
                                    {isLoading &&(
                                        <div className="spinner-border text-light spinner-border-sm ms-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='mb-4'>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th><input type="checkbox" disabled={true} /></th>
                                        <th>Evrak No</th>
                                        <th>Yapılandırma Türü</th>
                                        <th className='text-center'>Vadesi</th>
                                        <th>Taksit No</th>
                                        <th className='text-end'>Yapılandırma Tutarı</th>
                                        <th className='text-end'>Gecikme Faizi</th>
                                        <th className='text-end'>Gecikme Zammı</th>
                                        <th className='text-end'>Toplam Taksit Tutarı</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {structeredDebtsState.map((debt:any, index:any) => (
                                        <tr key={index} className={`${findStructuredTableRowColor(debt)} ${isCheckedDebt(debt, index)? 'selected':'' }`}  onClick= {()=>handleOnChange(debt)}>
                                            <td> 
                                                <input type="checkbox" checked={isCheckedDebt(debt, index)} id={index} onChange={()=>handleOnChange(debt)}/>
                                            </td>
                                            <td>{debt.documentNumber}</td>
                                            <td>{debt.installmentTypeName}</td>
                                            <td className='text-center'>{ dateFormat(debt.lastPaymentDate)}</td>
                                            <td>{debt.periodOrder}</td>
                                            <td className='text-end'>{moneyFormat(debt.taxDebtAmount)}</td>
                                            <td className='text-end'>{moneyFormat(debt.interestDebtAmount)}</td>
                                            <td className='text-end'>{moneyFormat(debt.lateFeeAmount)}</td>
                                            <td className='text-end'>{moneyFormat(debt.totalDebtAmount)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                      
                    </div>
                </div>
            )}
            {(isLoading) &&(
                <div className='d-flex justify-content-center'>
                    <div className="row spinner-border text-success spinner-border ms-2 spinner spinner-margin-top" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )} 
            {(showErrorMessage && !isLoading) &&(
                <div className="error-page-height">
                    <div className='text-center'>
                        <i className="fa fa-exclamation-circle text-danger me-3 display-4 mt-1 text-center"></i> 
                        <p className="align-middle mx-auto my-auto">
                            Beklenmedik bir hata oluştu. Lütfen daha sonra tekrar deneyiniz !
                        </p>
                    </div>  
                    
                </div>
            )}
            {(!showErrorMessage && !isLoading && debtsState.length === 0) &&(
                <div className="error-page-height">
                    <div className='text-center'>
                        <p className="align-middle mx-auto my-auto">
                            Hiç borcunuz bulunmamaktadır.
                        </p>
                    </div>  
                </div>
            )}
            {(!isLoading) &&(
                <div className='float-end'>
                    <a href={environment.EBELEDIYE_LINK}>
                        <button type="button" className="btn btn-primary-color text-white me-3 mb-1 fw-bold" >{environment.EBELEDIYE_TEXT}</button>
                    </a>
                    <button type="button" className='btn btn-secondary me-3 mb-1 fw-bold text-white' onClick={back} disabled={isSubmittingState || isExportPdfSubmittingState}>Geri Dön</button>
                    <button className='btn btn-secondary me-3 mb-1 fw-bold' onClick={exportDebtPdf} disabled={showErrorMessage || structeredDebtsState.length === 0 || isSubmittingState || isExportPdfSubmittingState}>Tüm Borçları Yazdır
                    {isExportPdfSubmittingState &&(
                        <div className="spinner-border text-light spinner-border-sm ms-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    )}
                    </button>
                    <button type="button" className='btn btn-success mb-1 fw-bold'  onClick={onSubmit} disabled={showErrorMessage || selectedDebtState === undefined || selectedDebtState === null || isSubmittingState || isExportPdfSubmittingState}>Seçili Borçları Ödemek İçin Tıklayınız
                    {isSubmittingState &&(
                        <div className="spinner-border text-light spinner-border-sm ms-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    )}
                    </button>
                </div>
            )}
            <ErrorMessageModal toggle = {errorMessageModalToggle} modalOpen = {errorMessageModalOpen} message = {errorMessage}/>   
        </>
    );
  };