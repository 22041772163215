import React from 'react'
import * as environment from '../../config/environment';
import { year } from '../../helpers/TodayDate';
import  "./Footer.css";
export default function Footer() {
  return (
    <footer className='border-top'>
    <div className='text-center'>
      <div className='text-muted'>{environment.FOOTER_PREFIX} Copyright © {year} Tüm Hakları Saklıdır 
      </div>
      <div className='text-muted'>
          Powered By <a href={environment.FOOTER_SAMPAS_LINK} target={"blank"}> {environment.FOOTER_SAMPAS_TEXT} </a> 
      </div>
    </div>
  </footer>
  )
}
