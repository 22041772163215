import axios, { AxiosInstance } from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import StorageService from "../services/StorageService";
import {
  encryptWithAes,
  generateAesIV,
  generateAesKey,
} from "../helpers/AesHelper";
import IEncryptedApiRequest from "../models/IEncryptedApiRequest";
import { encryptWithPublicKey } from "../helpers/RsaHelper";

export abstract class BaseAPI {
  protected axiosInstance: AxiosInstance | any = null;

  constructor(baseUrl: string) {
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      withCredentials: true,
    });
    this.addResponseInterceptor();
  }
  private defaultErrorMessage =
    "Beklenmedik bir hata oluştu lütfen daha sonra tekrar deneyiniz.";

  protected get(endpoint?: string, params?: any): Promise<any> {
    return this.axiosInstance.get(endpoint, params).then((response: any) => {
      return response.data;
    });
  }
  protected getFile(endpoint?: string, params?: any): Promise<any> {
    params.responseType = "blob";
    params.observe = "response";
    return this.axiosInstance.get(endpoint, params).then((response: any) => {
      return response;
    });
  }

  protected post(
    endpoint: string,
    params?: any,
    encrypted: boolean = false
  ): Promise<any> {
    if (encrypted) {
      var aesKey = generateAesKey();
      var aesIv = generateAesIV();
      let encryptedData = encryptWithAes(aesKey, aesIv, JSON.stringify(params));
      let encryptedAes = encryptWithPublicKey(aesKey + aesIv);
      if (encryptedAes) {
        let enryptedRequest: IEncryptedApiRequest = {
          encryptedData: encryptedData,
          aesKey: encryptedAes,
        };
        return this.axiosInstance
          .post(endpoint, enryptedRequest)
          .then((response: any) => {
            return response.data;
          });
      } else {
        toast.error(this.defaultErrorMessage);
        return Promise.reject();
      }
    } else {
      return this.axiosInstance.post(endpoint, params).then((response: any) => {
        return response.data;
      });
    }
  }

  private addResponseInterceptor() {
    this.axiosInstance.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: any) => {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            StorageService.clearAll();
            return (window.location.href = "/");
          } else if (error.response.data && error.response.data.message) {
            toast.error(
              error.response?.data?.errors?.length > 0
                ? error.response?.data?.errors[0]
                : error.response.data?.message
            );
          } else {
            toast.error(this.defaultErrorMessage);
          }
        } else {
          toast.error(this.defaultErrorMessage);
        }

        return Promise.reject(error);
      }
    );
  }
}
