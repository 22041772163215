import React from 'react';
import './App.css';
import * as environment from './config/environment';
import Layout from './layouts/Layout';
import AppRoute from './routes/AppRoute';
const App = () => {
  return (
    <>
      <Layout>
        <AppRoute/> 
      </Layout>
    </>
  );
}
export default App;
