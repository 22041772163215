const dateFormat = (date:string) => {
    if (date) {
        let dd = date.slice(8,10);
        let mm = date.slice(5,7);
        let yyyy = date.slice(0,4);
        const formatDate = dd + '/' + mm + '/' + yyyy;
        return formatDate;
    } else{
        return "";
    }

}

export {dateFormat}