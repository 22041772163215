import React, { useEffect } from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from '../hooks/UseCountDown';
import './UseCountDownTimer.css'
const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Zaman Aşımı</span>
      <p>Lütfen doğrulama kodunu tekrar alınız.</p>
    </div>
  );
};

const ShowCounter = ({ minutes, seconds }:any) => {
  const minute = (minutes < 10? '0' : '') + minutes;
  const second = (seconds < 10? '0' : '') + seconds;
  return (
    <div className="show-counter">
      <div className="countdown-link">
        <DateTimeDisplay value={minute} type={'Mins'} isDanger={minutes < 1 ? true: false} />
        <span>:</span>
        <DateTimeDisplay value={second} type={'Seconds'} isDanger={minutes < 1 ? true: false} />
      </div>
    </div>
  );
};

const CountdownTimer = ({ targetDate, changeInputDisabled}:any, props:any) => {
  const [minutes, seconds] = useCountdown(targetDate);
  const changeTime=()=>{
    if (minutes + seconds <= 0) {
      return changeInputDisabled(true);
    } else {
      return changeInputDisabled(false);
    }
  }
  useEffect(() => {
    changeTime();
  }, [minutes])
  
  return(
    <>
      {(minutes + seconds <= 0)&&(
        <ExpiredNotice />
      )}
      {(minutes + seconds > 0)&&(
        <ShowCounter minutes={minutes} seconds={seconds} />
      )}
    </>
  )
};

export default CountdownTimer;
