import * as environment from '../config/environment';
import ISelectedDebtsRequest from "../models/ISelectedDebtRequest";
import { DebtTypeEnum } from '../types/enums/DebtTypeEnum';
import { BaseAPI } from "./BaseAPI";

class DebtAPI extends BaseAPI {
  
  constructor () {
    const baseUrl = environment.API_URL + "debts";
    super(baseUrl);
  }

  queryStructeredDebts(){
    return this.get("structured");
  }

  queryUnStructeredDebts(){
    return this.get("unstructured");
  }

  setSelectedDebts(selectedDebtsRequest:ISelectedDebtsRequest){
    return this.post("selected-debts", selectedDebtsRequest, true);
  }
  
  exportDebtPdf (debtType:DebtTypeEnum) {
    return this.getFile("export-pdf", {params:{debtType:debtType}});
  }

}

export default new DebtAPI();