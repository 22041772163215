import * as environment from '../config/environment';
import IOtpRequest from "../models/IOtpRequest";
import IVerifyOTPRequest from "../models/IVerifyOTPRequest";
import { BaseAPI } from "./BaseAPI";
import StorageService from "../services/StorageService";
import IVerifyOTP from '../models/IVerifyOTP';
import IEncryptedApiRequest from '../models/IEncryptedApiRequest';

class AuthAPI extends BaseAPI {
  
  constructor () {
    const baseUrl = environment.API_URL + "auth";
    super(baseUrl);
  }

  sendOtp(otpRequest:IOtpRequest) {
    StorageService.clearAll();
    return this.post("otp", otpRequest, true);
  }

  verifyOtp(verifyOTPRequest:IVerifyOTPRequest){
    return this.post("otp/verify", verifyOTPRequest, true)
    .then(response => {
      let verifydata:IVerifyOTP = response?.data;
      if (response?.success) {
        StorageService.setUserInfo(verifydata);
      }
      return response.data;
    });
  }

}

export default new AuthAPI();