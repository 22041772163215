import {today} from './TodayDate';

const findUnstructuredTableRowColor =(data:any)=> {
    if(data.discountAmount > 0){
        return "text-row-discount";
    }else if(today > data.lastPaymentDate){
        return "text-row-late";
    }
}

export {findUnstructuredTableRowColor}