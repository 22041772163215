import React from 'react'
import {BrowserRouter, Route, Routes } from 'react-router-dom'
import PaymentSecurity from '../pages/payment-security/PaymentSecurity';
import DebtQuery from '../pages/debt-query/DebtQuery';
import SelectedDebts from '../pages/selected-debts/SelectedDebts';
import MakePayment from '../pages/make-payment/MakePayment';
import PaymentResult from '../pages/payment-results/PaymentResult';
import { ToastContainer } from 'react-toastify';
export default function AppRoute() {
  return (
    <>      
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<PaymentSecurity/>}/>
              <Route path="/debt-query" element={<DebtQuery/>}/>
              <Route path="/selected-debts" element={<SelectedDebts/>}/>
              <Route path="/payment-result" element={<PaymentResult/>}/> 
              <Route path="/make-payment" element={<MakePayment/>}/>
          </Routes>
          <ToastContainer position='top-center' autoClose={10000}/> 
      </BrowserRouter>  
    </>
  )
}
