import React, { useEffect, useState } from 'react'
import {  useLocation, useNavigate } from 'react-router-dom'
import './SelectedDebts.css'
import SessionStorageSevice from '../../services/StorageService';
import { moneyFormat } from '../../helpers/MoneyFormat';
import DebtsTable from '../../components/DebtsTable';
import BankAPI from '../../apis/BankAPI';
import IBank from '../../models/IBank';
import * as environment from '../../config/environment';
export default function SelectedDebts() {
  const [banksState, setBanksState] = useState<Array<IBank>>([]);
  const userInfo =  SessionStorageSevice.getUserInfo();
  const {state}:any = useLocation();
  const navigate = useNavigate();

  const onSubmit = (bank:IBank, event:any)=> {
    event.preventDefault();
    navigate("/make-payment",{state:{selectedDebts:state.selectedDebts, paymentSummary:state.paymentSummary, debtType:state.debtType, selectedBank:bank}});
  }
  const getActiveBankInfo = () => {
    BankAPI.getActiveBanks().then((response: any) => {
      if(response.success) {
        setBanksState(response.data);
      } 
    })
    .catch((e: Error) => {
    });
  }

  useEffect(() => {
    if(!state || !state.selectedDebts || !state.paymentSummary || !state.debtType || !userInfo){
      navigate("/");
    }else{
      getActiveBankInfo();
    }
  }, [])

  const back = () => {
    navigate("/debt-query");
  }
  return (
    <div className="container-fluid p-3">
      <div className="row">
        <p>Sayın {userInfo?.fullName} Ödemek İçin Seçtiğiniz Borçlar Aşağıda Listelenmiştir. Lütfen Kontrol Edip Ödeme İşlemi Başlatın.</p>
        <div className="col-sm-12">
          <DebtsTable selectedDebts ={state?.selectedDebts} debtType={state?.debtType}/>
        </div>
        <div>
          <form action="">
            <div className='row col-xxl-6 col-xl-8 col-lg-10 col-sm-12'>
              <div className='row mb-4 col-sm-6 col-12'>
                <label className="col-sm fw-bold mt-1">Toplam Vergi Borcu</label>
                <div className='col-sm'>
                  <input type="text" className="form-control text-end" value={moneyFormat(state?.paymentSummary?.taxAmount)} disabled={true}/>
                </div>
              </div>
              <div className='row mb-4 col-sm-6 col-12'>
                <label className="col-sm fw-bold mt-1">Toplam Gecikme Faizi</label>
                <div className='col-sm'>
                  <input type="text" className="form-control text-end" value={moneyFormat(state?.paymentSummary?.interestAmount)} disabled={true}/>
                </div>
              </div>
            </div>
            <div className='row col-xxl-6 col-xl-8 col-lg-10 col-sm-12'>
              <div className='row mb-4 col-sm-6 col-12'>
                <label className="col fw-bold mt-1">Toplam Gecikme Kdv Tutarı</label>
                <div className='col-sm'>
                  <input type="text" className="form-control text-end" value={moneyFormat(state?.paymentSummary?.delayedVatAmount)} disabled={true}/>
                </div>
              </div>
              <div className='row mb-4 col-sm-6 col-12'>
                <label className="col fw-bold mt-1">Toplam Gecikme Tutarı</label>
                <div className='col-sm'>
                  <input type="text" className="form-control text-end" value={moneyFormat(state?.paymentSummary?.lateFeeAmount)} disabled={true}/>
                </div>
              </div>
            </div>
            <div className='row col-xxl-6 col-xl-8 col-lg-10 col-sm-12'>
              <div className='row mb-4 col-sm-6 col-12'>
                <label className="col-sm fw-bold mt-1">Toplam İndirim Tutarı</label>
                <div className='col-sm'>
                  <input type="text" className="form-control text-end" value={moneyFormat(state?.paymentSummary?.discountAmount)} disabled={true}/>
                </div>
              </div>
              <div className='row mb-4 col-sm-6 col-12' >
                <label className="col-sm fw-bold mt-1 text-success">TOPLAM ÖDENECEK TUTAR</label>
                <div className='col-sm'>
                  <input type="text" className="form-control text-end fw-bold" value={moneyFormat(state?.paymentSummary?.paymentAmount)} disabled={true}/>
                </div>
              </div>
            </div>
            <div className='row mb-4 col-sm-12'>
              <p>
                Ödeme işlemini başlatmak için ödeme logosuna tıklayınız. Lütfen ödeme sayfasını kapatmadan önce ödeme işleminizin bitmesini bekleyiniz.
              </p>
            </div>
            <div className="mb-4 bank-container">
              {banksState.map((bank:IBank, index:any) => (
                <div className="me-5" key={index}>
                  {bank.logoBase64 &&(   
                    <button type="button" className="border-0 p-0" onClick={(e)=>{onSubmit(bank,e)}}>
                      <img className='btn-bank' alt='bank-logo' src={`data:image/jpeg;base64, ${bank.logoBase64}`} />  
                    </button>      
                  )}
                  {!bank.logoBase64 &&(   
                    <button type="button" className="btn btn-primary-color text-white fw-bold p-0 btn-bank" onClick={(e)=>{onSubmit(bank,e)}}>
                      {bank.bankName} <br/> {bank.isSecure3d &&("3D SECURE")} İLE ÖDEME YAPMAK İÇİN TIKLAYINIZ
                    </button>    
                  )}
                </div> 
              ))}
            </div>
            <div>
              <a href={environment.EBELEDIYE_LINK}>
                <button type="button" className="btn btn-primary-color text-white me-3 fw-bold mb-1" >{environment.EBELEDIYE_TEXT}</button>
              </a>
              <button type="button" className="btn btn-secondary me-3 text-white mb-1 fw-bold" onClick={back}>
                Geri Dön 
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
