import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


export default function ErrorMessageModal({modalOpen, toggle, message}:any) {
    const nodeRef = React.useRef(null);

    return (
        <>
            <Modal isOpen={modalOpen} toggle={toggle} className="modal-dialog-centered modal-lg" ref={nodeRef} backdrop="static">
                <ModalHeader toggle={toggle} >Uyarı</ModalHeader>
                <ModalBody>
                    <div className='mb-5'>
                        <div className='text-center'>
                            <i className="fa fa-exclamation-circle text-danger me-3 display-4 mt-1 text-center"></i> 
                            <p className="align-middle mx-auto my-auto">
                                {message}
                            </p>
                        </div>  
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className='btn btn-secondary me-2' onClick={()=>{toggle();}}>Kapat</button>
                </ModalFooter>
            </Modal>
        </>
    )
}
