import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PaymentResultSendEmailModalSubmitForm } from '../../../types/form-inputs/PaymentResultSendEmailModalSubmitForm';
import ISendPaymentDetailByEmailRequest from '../../../models/ISendPaymentDetailByEmailRequest';
import PaymentAPI from '../../../apis/PaymentAPI';
import { toast } from 'react-toastify';

export default function SendEmailModal({modalOpen, toggle, debtType, referenceId, setSendEmailModalOpen}:any) {
    const nodeRef = React.useRef(null);
    const [isLoadingState, setIsLoadingState] = useState(false);
    const validationSchema = yup.object().shape({
        email: yup.string().required("Email adresi gereklidir").email("Geçerli bir email adresi giriniz"),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
      } = useForm<PaymentResultSendEmailModalSubmitForm>({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: PaymentResultSendEmailModalSubmitForm, event:any) => {
        event.preventDefault();
        setIsLoadingState(true);
        let sendPaymentDetailByEmailRequest : ISendPaymentDetailByEmailRequest = {
            debtType:debtType,
            email:data.email,
            referenceId:referenceId
        };
        PaymentAPI.sendPaymentDetailByEmail(sendPaymentDetailByEmailRequest).then((response: any) => {
            setIsLoadingState(false);
            setSendEmailModalOpen(false);
            reset();
            if(response.success) {
                toast.success(response.data);
            }
        })
          .catch((e: Error) => {
            setIsLoadingState(false);
            reset();
            setSendEmailModalOpen(false);
        });
    };

    useEffect(() => {
    }, [toggle]);
     
    return (
        <>
            <Modal isOpen={modalOpen} toggle={toggle} className="modal-dialog-centered modal-lg" ref={nodeRef} backdrop="static">
                <form onSubmit={handleSubmit(onSubmit)} className="p-3">
                    <ModalHeader toggle={toggle}>Mail Adresime Gönder</ModalHeader>
                    <ModalBody>
                        <div className="form-group row mb-4">
                            <div className='col-sm-3'>
                                <label className='mt-1'>Eposta</label>
                            </div>
                            <div className='col-sm-6'>
                                <input
                                type="text"
                                {...register('email')}
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">{errors.email?.message}</div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button"className='btn btn-secondary me-2 mb-1 fw-bold' onClick={()=>{toggle();reset();}} disabled={isLoadingState}>Vazgeç</button>
                        <button className='btn btn-success mb-1 fw-bold' type="submit" disabled={isLoadingState}>
                            Gönder
                            {isLoadingState &&(
                                <div className="spinner-border text-light spinner-border-sm ms-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            )}
                        </button>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    )
}
