import React, { useImperativeHandle } from "react";
import * as environment from '../config/environment';
import ReCAPTCHA from "react-google-recaptcha";

const Recaptcha = React.forwardRef((props:any, ref) => {
    
    const captchaRef = React.useRef<ReCAPTCHA>(null);
    const handleSubmit =  (e:any) =>{
        const token = captchaRef.current?.getValue();
        return token;
    }
    
    const reset =()=>{
        props.changeHandleRecaptcha(false);
        captchaRef.current?.reset();
    }

    const onChange=(t:any)=>{
        props.changeHandleRecaptcha(true);
    }

    useImperativeHandle(ref, () => ({
        handleSubmit,
        reset
    }));
    return (
       <>
            <ReCAPTCHA sitekey={environment.GOOGLE_RECAPTCHA_KEY} hl="tr" size="normal" ref={captchaRef} onExpired ={reset}
                onChange={onChange}
            />
       </> 
    );
  });

  export default Recaptcha;