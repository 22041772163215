import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { moneyFormat } from "../../../../helpers/MoneyFormat";
import { findUnstructuredTableRowColor } from "../../../../helpers/FindUnstructuredTableRowColor";
import './UnstructuredDebts.css'
import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import DebtAPI from '../../../../apis/DebtAPI';
import { useNavigate } from 'react-router-dom';
import IUnstructuredDebt from '../../../../models/IUnstructuredDebt';
import { DebtTypeEnum } from '../../../../types/enums/DebtTypeEnum';
import { DiscountTypeEnum } from '../../../../types/enums/DiscountTypeEnum';
import ISelectedDebtsRequest from '../../../../models/ISelectedDebtRequest';
import ISelectedDebtSummary from '../../../../models/ISelectedDebtSummary';
import { downloadFile } from '../../../../helpers/DownloadFile';
import { today } from '../../../../helpers/TodayDate';
import SessionStorageSevice from '../../../../services/StorageService';
import ErrorMessageModal from '../../../../components/ErrorMessageModal';
import { dateFormat } from '../../../../helpers/DateFormat';
import * as environment from '../../../../config/environment';

export default function UnstructuredDebts() {
    const [debtsState, setDebtsState] = useState<Array<IUnstructuredDebt>>([]);
    const [filteredDebtsState, setFilteredDebtsState] = useState<Array<IUnstructuredDebt>>([]);
    const [isAllSelectedState, setIsAllSelectedState] = useState(false);
    const [selectedDebtsState, setSelectedDebtsState] = useState<Array<IUnstructuredDebt>>([]);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [isSubmittingState, setIsSubmittingState] = useState(false);
    const [isExportPdfSubmittingState, setIsExportPdfSubmittingState] = useState(false);
    const [hasDiscountRadioState, setHasDiscountRadioState] = useState(DiscountTypeEnum.HasDiscount);
    const [systemOptionsState, setSystemOptionsState] = useState<Array<string>>([]);
    const [totalDebtAmountState, setTotalDebtAmountState] = useState(0);
    const [errorMessageModalOpen, setErrorMessageModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState<String>();
    const navigate = useNavigate();
    const userInfo =  SessionStorageSevice.getUserInfo();
    const errorMessageModalToggle = () => setErrorMessageModalOpen(!errorMessageModalOpen);
    const {handleSubmit,handleChange,values,resetForm} = useFormik({
        initialValues:{
            systemName:"",
            subscriberNumber:"",
            parcel:"",
            year:"",
            period:"",
            orderNo:"",
        },
        onSubmit: (values) => {
        },
    });
    
    const reset =()=>{
        resetForm();
    };
    
    const handleCheckItemSelectedOnChange = (pos:number,selectedDebt:IUnstructuredDebt) => {
        let newArray:IUnstructuredDebt[] = [...selectedDebtsState];
        let index = newArray.findIndex( debt => debt.referenceId === selectedDebt.referenceId);
        //Seçimi kaldırdıysa
        if (index > -1) {
        
            if (selectedDebt.systemName === "Harcamalara Katılım" && selectedDebt.discountAmount > 0 && hasDiscountRadioState === DiscountTypeEnum.HasDiscount) {
                newArray = newArray.filter( x => !(x.systemName === selectedDebt.systemName 
                                                            && x.subscriberNumber === selectedDebt.subscriberNumber
                                                            && x.year === selectedDebt.year
                                                            && x.orderNo === selectedDebt.orderNo
                                                            && x.mainIncomeCode === selectedDebt.mainIncomeCode
                                                            && x.mainIncomeSubCode === selectedDebt.mainIncomeSubCode));
            } else {
                newArray = newArray.filter( x => !(x.systemName === selectedDebt.systemName 
                                                            && x.subscriberNumber === selectedDebt.subscriberNumber
                                                            && x.year === selectedDebt.year
                                                            && x.period === selectedDebt.period
                                                            && x.lastPaymentDate === selectedDebt.lastPaymentDate
                                                            && x.orderNo === selectedDebt.orderNo
                                                            && x.mainIncomeCode === selectedDebt.mainIncomeCode
                                                            && x.mainIncomeSubCode === selectedDebt.mainIncomeSubCode));               
            }
            if (newArray.filter(d => d.systemName === selectedDebt.systemName && d.lastPaymentDate > selectedDebt.lastPaymentDate 
                &&  selectedDebt.referenceId !== d.referenceId).length > 0 ) {
                setErrorMessage("Vadesi seçtiğiniz borçtan daha eski borcunuz bulunmaktadır öncelikle vadesi eski borçlarınızı ödemelisiniz");
                errorMessageModalToggle();
                return;
            } 
        } else {
            const {success,message,debts} = validateSelectedCheckItem(selectedDebt, filteredDebtsState);
            if (success && debts) {
                newArray.push(...debts);
            } else {
                setErrorMessage(message);
                errorMessageModalToggle();
                return;
            }
        }
        setSelectedDebtsState([...newArray]);
    };
    const validateSelectedCheckItem = (selectedDebt:IUnstructuredDebt, searchDebts:Array<IUnstructuredDebt>):{success:boolean,message?:string,debts?:Array<IUnstructuredDebt>} => {
        let passedPeriodDebtControl = true; 
        
        if (userInfo.passedPeriodDebtControl !== undefined && userInfo.passedPeriodDebtControl === "H") {
            passedPeriodDebtControl = false;
        }

        if (passedPeriodDebtControl === true && debtsState.filter(d => d.systemName === selectedDebt.systemName && d.lastPaymentDate < selectedDebt.lastPaymentDate  
            && selectedDebtsState.filter(x => x.referenceId === d.referenceId).length === 0).length > 0 ) {
            return { 
                success:false, 
                message:"Vadesi seçtiğiniz borçtan daha eski borcunuz bulunmaktadır öncelikle vadesi eski borçlarınızı ödemelisiniz"
            }; 
        } else if(selectedDebt.systemName === "Harcamalara Katılım" && selectedDebt.discountAmount > 0 && DiscountTypeEnum.HasDiscount) {
            // Check edilen satırın grup adı “Harcamalara Katılım” ve indirim tutarı sıfırdan büyük ise 
            // Grup Adı, yılı, İs/Ya da Abone No,sira no,ana_gelir_kodu,ana_gelir_alt_kodu aynı olan diğer satırlar otomatik checki doldurulacak
            let otherDebts = searchDebts.filter( x => x.systemName === selectedDebt.systemName 
                                                                && x.subscriberNumber === selectedDebt.subscriberNumber
                                                                && x.year === selectedDebt.year
                                                                && x.orderNo === selectedDebt.orderNo
                                                                && x.mainIncomeCode === selectedDebt.mainIncomeCode
                                                                && x.mainIncomeSubCode === selectedDebt.mainIncomeSubCode);
            let otherDebts2 = debtsState.filter( x => x.systemName === selectedDebt.systemName
                                                                && x.subscriberNumber === selectedDebt.subscriberNumber
                                                                && x.year === selectedDebt.year
                                                                && x.orderNo === selectedDebt.orderNo
                                                                && x.mainIncomeCode === selectedDebt.mainIncomeCode
                                                                && x.mainIncomeSubCode === selectedDebt.mainIncomeSubCode);
            if (otherDebts2.length > otherDebts.length) {
                return { 
                    success:false, 
                    message:"Seçmiş olduğunuz borçların dışında Harcamalara Katılım borcunuz bulunmaktadır"
                };    
            }
            return {
                success:true,
                debts:otherDebts
            }; 
        } else {
            //Check edilen satırda  Grup Adı,Yılı,Dönemi, Ana Gelir Kodu,Ana Gelir alt kodu, sıra numarası,  
            //is_yada abone no, son ödeme tarihi  aynı olan diğer satırları da check edecek
            let otherDebts = searchDebts.filter( x => x.systemName === selectedDebt.systemName 
                                                                && x.subscriberNumber === selectedDebt.subscriberNumber
                                                                && x.year === selectedDebt.year
                                                                && x.period === selectedDebt.period
                                                                && x.lastPaymentDate === selectedDebt.lastPaymentDate
                                                                && x.orderNo === selectedDebt.orderNo
                                                                && x.mainIncomeCode === selectedDebt.mainIncomeCode
                                                                && x.mainIncomeSubCode === selectedDebt.mainIncomeSubCode);
            let otherDebts2 = debtsState.filter( x => x.systemName === selectedDebt.systemName 
                                                                && x.subscriberNumber === selectedDebt.subscriberNumber
                                                                && x.year === selectedDebt.year
                                                                && x.period === selectedDebt.period
                                                                && x.lastPaymentDate === selectedDebt.lastPaymentDate
                                                                && x.orderNo === selectedDebt.orderNo
                                                                && x.mainIncomeCode === selectedDebt.mainIncomeCode
                                                                && x.mainIncomeSubCode === selectedDebt.mainIncomeSubCode);
            
            if (otherDebts2.length > otherDebts.length) {
                return { 
                    success:false, 
                    message:"Filtrelemiş olduğunuz borçların dışında borcunuz bulunmaktadır"
                }; 
            }
            return {
                success:true,
                debts:otherDebts
            }
        }
    }
    const validateSelectedCheckItems = (selectedDebts:Array<IUnstructuredDebt>, searchDebts:Array<IUnstructuredDebt>):{success:boolean,message?:string} => {
        
        if(selectedDebts.length === 0){
            return{success:false,message:"Ödeme yapmak için en az bir borç seçmelisiniz"};
        }

        for(let i = 0; i < selectedDebts.length; i++) {
            const {success,message} = validateSelectedCheckItem(selectedDebts[i],searchDebts);
            if (success === false) {
                return{success:success,message:message};
            }
        };
        return{success:true};
    }

    const handleOnAllSelectedChange = (val:boolean) => {
        if(filteredDebtsState.length === 0) return;
        if(values.subscriberNumber || values.period || values.parcel || values.year) return;

        if(val){
            setSelectedDebtsState([...filteredDebtsState]);
        }else{
            setSelectedDebtsState([]);
        }
        setIsAllSelectedState(val); 
        return val;
    };
    
    useEffect(() => {
        handleOnAllSelectedChange(false);
        callUnstructuredDebtsQuery();
    }, []);

    useEffect(() => {
        filter();
    }, [debtsState]);

    useEffect(() => {
        let sumSelectedDebts = selectedDebtsState.reduce(function(prevTotalDebt:any, currentTotalDebt:any) {
            return prevTotalDebt + currentTotalDebt.totalDebtAmount;
        }, 0);
        
        setTotalDebtAmountState(sumSelectedDebts);
        let isAllSelected = (filteredDebtsState.length > 0 && selectedDebtsState.length === filteredDebtsState.length) ? true : false;
        setIsAllSelectedState(isAllSelected);
    },[selectedDebtsState]);

    const filteredDebtFunction = (data:any) => {
        if(values.systemName && data.systemName.toLowerCase() !== values.systemName.toLowerCase()) return false;
        if(values.subscriberNumber && data.subscriberNumber !== values.subscriberNumber) return false;
        if(values.parcel && !data.parcel?.includes(values.parcel)) return false;
        if(values.period && data.period !== values.period) return false;
        if(values.year && data.year !== Number(values.year)) return false;
        if(values.orderNo && data.orderNo !== values.orderNo) return false;
        return true;
    }
    
    const filter = () => {
        
        const debtData = debtsState.filter(filteredDebtFunction);
        let discountDebtData = returnFilteredDataWithDiscount(hasDiscountRadioState, debtData);
        setFilteredDebtsState(discountDebtData);
        setSelectedDebtsState([]);
        setIsAllSelectedState(false);  
    }

    const callUnstructuredDebtsQuery = () => {
        setIsloading(true);
        DebtAPI.queryUnStructeredDebts().then((response: any) => {
            setIsloading(false);
            setShowErrorMessage(false);
            if(response.success){
                setDebtsState(response.data);
                setFilteredDebtsState(response.data);
                getAllUnstructuredDebtType(response.data);
            }
        })
        .catch((e) => {
            setShowErrorMessage(true);   
            setIsloading(false);
        });
    }

    const getAllUnstructuredDebtType = (debts:Array<IUnstructuredDebt>) => {
        let options:string[] = debts.map((e)=>e.systemName);
        options = options.filter((n:any,i:any) => options.indexOf(n) === i);
        options.sort((a, b) => a.localeCompare(b));
        setSystemOptionsState(options);
    }

    const isCheckedDebt = (debt:IUnstructuredDebt) => {
        return selectedDebtsState.filter(d => d.referenceId === debt.referenceId).length > 0;
    }
    
    const onDiscountSelectionChangeValue =(event:any) => {
        // Kullanıcı Faydalanmak İstemiyorum’u seçerse Borç Tutarı ve Toplam Borç alanlarının üzerlerine indirim tutarları eklenecek.  
        // İndirim tutarı girdde sıfır gösterilecek Bu durumda Hkp için indirim kuralları pasif olacak. 

        let discountSelection = Number(event.target.value);
        let debtData = returnFilteredDataWithDiscount(discountSelection, filteredDebtsState);

        setFilteredDebtsState(debtData);
        setSelectedDebtsState([]);
        setHasDiscountRadioState(discountSelection);
    }

    const returnFilteredDataWithDiscount = (discountSelection:DiscountTypeEnum, existingDebts:IUnstructuredDebt[]) => {
        let newArray:IUnstructuredDebt[] = [...existingDebts];
        if (discountSelection === DiscountTypeEnum.NoDiscount) {
            newArray = existingDebts.map((debt)=> {
                if(debt.systemName === "Harcamalara Katılım" && debt.discountAmount > 0){
                    return {...debt, 
                            taxDebtAmount:debt.taxDebtAmount + debt.discountAmount, 
                            totalDebtAmount:debt.totalDebtAmount + debt.discountAmount,
                            discountAmount:0
                        } 
                }
                return debt;
            })

        } else {
            newArray = existingDebts.map((debt)=> {
                if(debt.systemName === "Harcamalara Katılım"){
                    const originalDebt = debtsState.find(x=>x.referenceId === debt.referenceId);
                    if(originalDebt) return originalDebt; 
                }
                return debt;
            })
        }
        return newArray;
    }
    const showRadioButton = () => {
        if ( hasDiscountRadioState === DiscountTypeEnum.NoDiscount || filteredDebtsState.find(debt => debt.systemName  === "Harcamalara Katılım" && debt.discountAmount > 0)) {
            return true;
        } else {
            return false;
        }
    }

    const onSubmit = (event:any) => {
        event.preventDefault();
        const {success,message}:any = validateSelectedCheckItems(selectedDebtsState, selectedDebtsState);
        if (success === false) {
            setErrorMessage(message);
            errorMessageModalToggle();
            return;
        }
        const debtIds = selectedDebtsState.map((x:any)=>x.referenceId);
        let selectedDebtsRequest:ISelectedDebtsRequest = {
            debtType:DebtTypeEnum.UnstructuredDebt,
            discountSelection:hasDiscountRadioState, 
            debtIds:debtIds
        }
        setIsSubmittingState(true);
        DebtAPI.setSelectedDebts(selectedDebtsRequest).then((response: any) => {
            setIsSubmittingState(false);
            let paymentSummary:ISelectedDebtSummary = response.data;
            if(response.success) {
                navigate("/selected-debts",{state:{selectedDebts:selectedDebtsState, paymentSummary:paymentSummary, debtType:DebtTypeEnum.UnstructuredDebt}});
            } 
          })
          .catch((e: Error) => {
            setIsSubmittingState(false);
        });
    }
    
    const back = () => {
        navigate("/");
    }

    const exportDebtPdf = () => {
        setIsExportPdfSubmittingState(true);
        DebtAPI.exportDebtPdf(DebtTypeEnum.UnstructuredDebt).then((response: any) => {
            setIsExportPdfSubmittingState(false);
            downloadFile(response, (userInfo?.registryNumber + "-YapılandırmasızBorçlar - " + today + ".pdf"));
        })
        .catch((e: Error) => {
            setIsExportPdfSubmittingState(false);
        });
    }

    return (
        <>
            {(!showErrorMessage && !isLoading && debtsState.length !== 0) &&(
                <div>
                    <div className='mt-4'>
                        {(showRadioButton() &&
                            <div className='row mb-3 col-lg-4'>
                                <span className='mb-3 fw-bold text-primary'>Harcamalara Katılım İndiriminiz Var</span>
                                <div className="col-sm-6 mb-3 col-md-auto col-lg-auto">
                                    <input className="form-check-input me-2" type="radio" name="hasDiscount"  value={DiscountTypeEnum.HasDiscount} defaultChecked onChange={onDiscountSelectionChangeValue}/>
                                    <label className="form-check-label" htmlFor="hasDiscount">
                                        Faydalanmak istiyorum
                                    </label>
                                </div>
                                <div className="col-sm-6 col-md-auto col-lg-auto mb-3">
                                    <input className="form-check-input me-2" type="radio" name="hasDiscount" value={DiscountTypeEnum.NoDiscount} onChange={onDiscountSelectionChangeValue}/>
                                    <label className="form-check-label" htmlFor="hasDiscount">
                                        Faydalanmak istemiyorum
                                    </label>
                                </div>
                            </div>
                        )}
                        <div className='col-lg-10 col-md-6 col-xl-6'>
                            <i className="fa fa-filter me-1 text-center fa-lg"></i> 
                            <span className='mb-3 fw-bold'>Borç Sorgulama Filtreleri</span>
                            <hr className='col-lg-8'/>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className='row col-lg-10 col-md-6 mb-4'>
                                <div className='col-lg'>
                                    <label className="mb-2">Grubu</label>
                                     <div>
                                        <select  className="form-select" aria-label="Default select example" name="systemName" value = {values.systemName} onChange = {handleChange}>
                                        <option value="">Tümü</option>
                                        {systemOptionsState.map((option:string, index:any) => (
                                            <option value={option} key={index}>{option}</option>
                                        ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg'>
                                    <label className="mb-2">Yılı</label>
                                    <div>
                                        <input type="text" className="form-control" id = 'year' value = {values.year} onChange = {handleChange}/>
                                    </div>
                                </div>
                                <div className='col-lg'>
                                    <label className="mb-2">Dönem</label>
                                    <div>
                                        <input type="number" className="form-control" id = 'period' value = {values.period} onChange = {handleChange}/>
                                    </div>
                                </div>
                                <div className='col-lg'>
                                    <label className="mb-2">Sıra No</label>
                                    <div>
                                        <input type="number" className="form-control" id = 'orderNo' value = {values.orderNo} onChange = {handleChange}/>
                                    </div>
                                </div>
                                <div className='col-lg'>
                                    <label className="mb-2">Abone No</label>
                                    <div>
                                        <input type="number" className="form-control" id = 'subscriberNumber' value = {values.subscriberNumber} onChange = {handleChange}/>
                                    </div>
                                </div>
                                <div className='col-lg'>
                                    <label className="mb-2">Ada/Parsel</label>
                                    <div>
                                        <input type="text" className="form-control" id = 'parcel' value = {values.parcel} onChange = {handleChange}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4 col-lg-10">
                                <div className="text-end">
                                    <button type="button" className="btn btn-secondary me-3 mb-1 fw-bold" onClick={reset} disabled={isLoading}>Temizle</button>
                                    <button type="submit" className="btn btn-success mb-1 fw-bold" onClick={callUnstructuredDebtsQuery} disabled={isLoading}>Borçları Listele
                                    {isLoading &&(
                                        <div className="spinner-border text-light spinner-border-sm ms-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='mb-4'>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>
                                        <input type="checkbox" checked={isAllSelectedState} onChange={(e)=> handleOnAllSelectedChange(e.target.checked)} id="isAllSelected" name="isAllSelected"/>
                                    </th>
                                    <th>Grubu</th>
                                    <th className='vh-155 text-center'>Son Ödeme Tarihi</th>
                                    <th className='vh-80'>Türü</th>
                                    <th className='vh-120'>İş/Abone No</th>
                                    <th className='vh-120'>Ada/Parsel</th>
                                    <th className='vh-250'>Adres</th>
                                    <th className='vh-80 text-center'>Yılı</th>
                                    <th className='vh-80 text-center'>Dönemi</th>
                                    <th className='vh-80'>Sıra No</th>
                                    <th className='vh-120 text-end'>Borç Tutarı</th>
                                    <th className='vh-120 text-end'>Gecikme Faizi</th>
                                    <th className='vh-140 text-end'>Gecikme Zammı</th>
                                    <th className='vh-120 text-end'>Toplam Borç</th>
                                    <th className='vh-120 text-end'>Yasal İndirim</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredDebtsState.map((debt:IUnstructuredDebt, index:any) => (
                                    <tr key={index} className={`${findUnstructuredTableRowColor(debt)} ${isCheckedDebt(debt)? 'selected':'' }`} onClick={() => {handleCheckItemSelectedOnChange(index,debt)}}>
                                        <td> 
                                            <input type="checkbox" checked={isCheckedDebt(debt)} id={index} onChange={() => handleCheckItemSelectedOnChange(index,debt)}/>
                                        </td>
                                        <td>{debt.systemName}</td>
                                        <td className='text-center'>{ dateFormat(debt.lastPaymentDate)}</td>
                                        <td>{debt.incomeName}</td>
                                        <td>{debt.subscriberNumber}</td>
                                        <td>{debt.parcel}</td>
                                        <td>{debt.address}</td>
                                        <td className='text-center'>{debt.year}</td>
                                        <td className='text-center'>{debt.period}</td>
                                        <td>{debt.orderNo}</td>
                                        <td className='text-end'>{moneyFormat(debt.taxDebtAmount)}</td>
                                        <td className='text-end'>{moneyFormat(debt.interestDebtAmount)}</td>
                                        <td className='text-end'>{moneyFormat(debt.lateFeeAmount)}</td>
                                        <td className='text-end'>{moneyFormat(debt.totalDebtAmount)}</td>
                                        <td className='text-end'>{moneyFormat(debt.discountAmount)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div className="row justify-content-end mb-4">
                        <label className="col-sm-1 mt-1 fw-semibold">Toplam Borç</label>
                        <div className='col-sm-1'>
                            <input type="text" className="form-control text-end" value={moneyFormat(totalDebtAmountState)} disabled={true}/>
                        </div>
                    </div>
                </div>
            )}
            {(isLoading) &&(
                <div className='d-flex justify-content-center'>
                    <div className="row spinner-border text-success spinner-border ms-2 spinner spinner-margin-top" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}   
            {(showErrorMessage && !isLoading) &&(
                <div className="error-page-height">
                    <div className='text-center'>
                        <i className="fa fa-exclamation-circle text-danger me-3 display-4 mt-1 text-center"></i> 
                        <p className="align-middle mx-auto my-auto">
                            Beklenmedik bir hata oluştu. Lütfen daha sonra tekrar deneyiniz!
                        </p>
                    </div>  
                </div>
            )}
            {(!showErrorMessage && !isLoading && debtsState.length === 0) &&(
                <div className="error-page-height">
                    <div className='text-center'>
                        <p className="align-middle mx-auto my-auto">
                            Hiç borcunuz bulunmamaktadır.
                        </p>
                    </div>  
                </div>
            )}
            {(!isLoading) &&(
                <div className='float-end'>
                    <a href={environment.EBELEDIYE_LINK}>
                        <button type="button" className="btn btn-primary-color text-white me-3 mb-1 fw-bold" >{environment.EBELEDIYE_TEXT}</button>
                    </a>
                    <button type="button" className='btn btn-secondary me-3 mb-1 text-white fw-bold' onClick={back} disabled={isSubmittingState || isExportPdfSubmittingState}>Geri Dön</button>
                    <button className='btn btn-secondary me-3 mb-1 fw-bold' onClick={exportDebtPdf} disabled={showErrorMessage || debtsState.length === 0 || isSubmittingState || isExportPdfSubmittingState}>Tüm Borçları Yazdır
                    {isExportPdfSubmittingState &&(
                        <div className="spinner-border text-light spinner-border-sm ms-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    )}
                    </button>
                    <button type="button" className='btn btn-success mb-1 fw-bold'  onClick={onSubmit} disabled={showErrorMessage || selectedDebtsState.length === 0  || isSubmittingState || isExportPdfSubmittingState}>Seçili Borçları Ödemek İçin Tıklayınız
                    {isSubmittingState &&(
                        <div className="spinner-border text-light spinner-border-sm ms-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    )}
                    </button>
                </div>
            )} 
            <ErrorMessageModal toggle = {errorMessageModalToggle} modalOpen = {errorMessageModalOpen} message = {errorMessage}/>        
        </>
    );
  };